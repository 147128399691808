.userCardDashboard {
    background-color: #fff !important;
    border: 2px solid #B1C9B0;
}
.container {
    max-width: 800px !important;
}
.vault-heading {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}
.vault-table {
    width: 100%;
}
.vault-table tr {
    border-color: #fff !important;
}
.vault-table tr td {
    font-size: 14px;
}
.vault-table tr td:last-child {
    text-align: right;
}
.vault-container {
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}
.vault-container h1 {
    margin-left: 20px;
}
.apy-details {
    float: right;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    margin-top: 8px;
    border-radius: 10px;
    background-color: #30cd8429;
    border: 1px solid #1cbb71a3;
}
@media (max-width: 1000px) {
    .vault-container {
        margin: 20px 60px !important;
        width: unset !important;
    }
}
.landing-card {
    height: 742.16px !important;
    width: 100%;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}
.landing-content {
    padding: 40px 5rem;
    float: left;
    width: 100%;
}
.landing-text {
    width: 100%;
    float: left;
    height: 100%;
}
.landing-text h1 {
    line-height: 60px;
    font-size: 40px;
    margin: 0;
}
.landing-text h1 span {
    font-style: italic;
    color: #3898f8;
}
.landing-text p {
    float: left;
    font-size: 18px;
    margin: 20px 30px 0 0;
    padding: 12px 20px;
    background-color: #f5f5f5;
    border-radius: 12px;
    min-width: 200px;
    text-align: center;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.landing-text .stats {
    float: left;
    width: 100%;
    margin: 60px 0 20px 0;
}
.landing-text p span {
    font-size: 24px;
    font-weight: 800;
    margin-left: 10px;
}
.landing-image img {
    opacity: 30%;
    position: absolute;
    top: -105px;
    right: 40px;
    rotate: 45deg;
}
.audited-by {
    float: left;
    width: 100%;
    margin: 10px 0 0 10px;
}
.audited-by span {
    font-size: 18px;
}
.audited-by img {
    margin: 20px 20px;
}

@media (max-width: 800px) {
    .landing-content {
        padding: 40px;
    }
    .landing-image img {
        opacity: 10%;
    }
    .socials {
        display: none;
    }
    .audited-by img {
        width: 150px;
    }
}
@media (max-width: 450px) {
    .landing-card {
        height: unset !important;
    }
}

.footer {
  float: left;
  width: 100%;
  height: calc(100vh - 855px) !important;
  min-height: 80px !important;
  background-color: #f5f5f5;
  padding: 0 80px;
}
.socials {
    float: left;
    margin-top: -5px;
}
.socials img {
    margin-right: 5px;
}
.footer ul {
  list-style: none;
  float: right;
}
.footer ul li {
  float: left;
  padding: 0 15px;
  font-weight: 400;
}
.footer ul li a {
  color: #222;
  text-decoration: none;
}
.footer ul li a:hover {
  text-decoration: underline;
}


