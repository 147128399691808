.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #00264d !important;
  /* border-radius: 28px !important; */
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: #00264d !important;
  /* border-radius: 28px !important; */
}
/* .nav-tabs .nav-link:hover{
background-color: #00264d;
} */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: black !important;
}
.nav-tabs {
  --bs-nav-tabs-border-width: none !important;
  display: none !important;
}
/* .tab-pane {
 padding: 2rem 3rem;
} */
.card {
  border: none !important;
  margin-bottom: 40px;
  color: #000 !important;
}
button#controlled-tab-example-tab-1 {
  transition: ease-in-out 0.5s;
  width: 85px;
  border-top: 1px solid #00264d;
  border-bottom: 1px solid #00264d;
  border-left: 1px solid #00264d;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
button#controlled-tab-example-tab-2 {
  transition: ease-in-out 0.5s;
  width: 85px;
  border-top: 1px solid #00264d;
  border-bottom: 1px solid #00264d;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
button#controlled-tab-example-tab-3 {
  transition: ease-in-out 0.5s;
  width: 85px;
  border-top: 1px solid #00264d;
  border-bottom: 1px solid #00264d;
  border-right: 1px solid #00264d;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
}
.list-unstyled {
  margin-top: 20px;
}
.supply-modal {
  border-radius: 10px;
}
.supply-modal h5 {
  font-weight: 800;
}
.data-field {
  float: right;
}
.form-section ul li {
  font-size: 14px;
}
.CommingSoon{

  mix-blend-mode: darken;
}