.supply-modal .ant-modal-content {
  border-radius: 10px;
}
.amount-input {
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}
.amount-info {
  color: #6c757d;
  font-size: 14px;
}
.amount-value {
  flex-grow: 1;
  border: none;
  box-shadow: none;
  max-width: 85% !important;
}
.max-btn {
  background-color: #00264d;
  border: none;
  padding: 0px 4px;
  font-size: 11px;
  height: 20px;
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 85px;
  bottom: 23px;
}
.transaction-overview {
  padding: 10px;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  margin-top: 10px;
}
:where(.css-dev-only-do-not-override-p8b6i).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #00264d;
  border-color: #00264d;
  background: #ffffff;
}
#components-slider-demo-mark h4 {
  margin: 0 0 16px;
}

#components-slider-demo-mark .ant-slider-with-marks {
  margin-bottom: 44px;
}

.ant-slider .ant-slider-handle::after {
  content: "";
  box-shadow: 0 0 0 2px #00264d !important;
}
.ant-slider .ant-slider-track {
  background-color: #00264d !important;
}
.ant-slider .ant-slider-dot-active {
  border-color: #00264d !important;
}
.ant-slider .ant-slider-dot {
  width: 1px !important;
  border-radius: 0% !important;
}
.supplyBtn {
  color: #ffffff;
  background: rgb(0,38,77) linear-gradient(30deg, rgba(0,38,77,1) 0%, rgba(77,162,255,1) 100%);
}
.withdraw-btn {
  font-size: 12px !important;
  font-weight: 800;
  color: #000 !important;
}
/* background: rgb(0,38,77);
background: linear-gradient(30deg, rgba(0,38,77,1) 0%, rgba(77,162,255,1) 100%); */
