#root {
  background-color: #f5f5f5;
  color: #000 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #000 !important;
}
h6 {
  font-size: 14px !important;
}
h5 {
  font-size: 16px !important;
  width: 100%;
}
.bg-body-nav {
  background-color: #222;
}
.wkit-dialog__overlay {
  background-color: #0000004a !important;
}
.wkit-address-select__address {
  width: 39px !important;
  overflow: hidden !important;
  color: #000 !important;
}
.wkit-connected-button__balance, .wkit-address-select__address {
  color: #fff !important;
}
.wkit-connected-container{
  width: 180px !important;
}
.wkit-button {
  width: 140px !important;
  display: inline-flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  text-decoration: none !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  background-color: #3898f8 !important;
  height: 40px !important;
  border-radius: 12px !important;
}
.wkit-button:hover {
  background-color: #1a88ff !important;
  transition: 0.3s;
}
.wkit-connected-container {
  border-radius: 12px !important;
  background-color: #181A1E !important;
}
.wkit-connected-button {
  width: 180px !important;
  padding: 10px !important;
  background-color: #181A1E !important;
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.wkit-address-select__right-arrow {
  color: #fff !important;
}
.wkit-disconnect-button {
  width: 152px !important;

  padding: 10px !important;
}
.wkit-dialog__content {
  border: none !important;
  border-radius: 12px !important;
  padding: 20px !important;
}
.wkit-dialog__header {
  margin-bottom: 0 !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #f5f5f5;
}
.wkit-select__scroll {
  padding: 0 0 10px 0;
}
.wkit-new-to-sui__link {
  color: #3898f8 !important;
}
.wkit-new-to-sui__link:hover {
  color: #1a88ff !important;
  transition: 0.3s;
}
.wkit-select__container {
  margin-top: 10px !important;
}
.wkit-select__title {
  display: none;
}
.wkit-select__scroll {
  margin-right: 0 !important;
  min-height: 0 !important;
} 
.wkit-select-item {
  color: #000 !important;
}
.wkit-select-item:hover {
  border-radius: 12px;
  background-color: #f5f5f5 !important;
  color: #000 !important;
}
.wkit-dialog__close {
  padding: 3px 6px 6px 6px !important;
  background-color: #ffffff !important;
  border: 2px solid #f5f5f5 !important;
  right: 20px !important;
  top: 14px !important;
}
.wkit-dialog__close:hover {
  color: #000 !important;
  border: 2px solid #000 !important;
  transition: 0.3s;
}
.wkit-disconnect-button__container {
  bottom: -45px !important;
  border-radius: 12px !important;
  z-index: 1;
  background-color: #181A1E !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.wkit-disconnect-button {
  color: #fff !important;
  width: 100% !important;
  text-align: left !important;
  background-color: #181A1E !important;
  color: #fff !important;
}
.wkit-new-to-sui {
  border-top: 1px solid #f5f5f5 !important;
}
.wkit-new-to-sui__text {
  color: #000 !important;
}
.navbar-logo {
  width: 160px !important;
}
.go2072408551, .go685806154 {
  color: #000 !important;
  border: none;
  border-radius: 12px;
  background-color: #fff;
}
.navbar-collapse ul {
  list-style: none;
  margin: 5px 0 0 10px;
  padding: 0;
}
.navbar-collapse ul li {
  float: left;
  padding: 0 15px;
  font-weight: 400;
}
.navbar-collapse ul li a {
  color: #f5f5f5d9;
  text-decoration: none;
}
.navbar-collapse ul li a:hover {
  color: #fff;
}
.active-link {
  color: #fff !important;
}
.beta-logo {
  font-size: 16px;
  background-color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 2px;
  margin-left: -8px;
}
.navbar-toggler {
  border: 1px solid #dee2e6 !important;
}
.DisconnectWallet,
.ConnectedWallet {
  color: white !important;
  background-color: #222222 !important;
}

.DisconnectWallet:hover,
.ConnectedWallet:hover {
  color: white !important;
  background-color: #222222 !important;
  cursor: pointer;
}

.ConnectedWallet,.ConnectedWallet:hover {
  border: 1px solid #6D757D !important;
}

ul.DisconnectWalletBtn {
  border: 1px solid #6D757D;
}
