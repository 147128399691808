body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: helvetica, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: helvetica, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

:root {
  --wkit-font-family: helvetica, 'Inter', sans-serif;
}
