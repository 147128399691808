.ant-modal-content {
  border-radius: 12px !important;
  padding: 30px !important;
}
.ant-form-item {
  margin-bottom: 20px;
}
.ant-form-item-row {
  margin-bottom: 0 !important;
}
.ant-input-outlined:focus {
  border-color: #3898f8 !important;
  box-shadow: 0 0 0 2px #3898f838 !important;
}
.ant-input {
  color: #000 !important;
}
.amount-input {
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}
.amount-info {
  color: #000 !important;
  font-size: 14px;
}
.amount-value {
  flex-grow: 1;
  border: none;
  box-shadow: none;
  padding: 5px 10px;
  max-width: 85% !important;
}
.max-btn {
  background-color: #000 !important;
  font-weight: 800;
  border: none;
  font-size: 14px;
  height: 20px;
  color: white;
  border-radius: 5px;
  position: absolute;
  right: 85px;
  bottom: 23px;
}
.transaction-overview {
  padding: 10px !important;
  border: 2px solid #f5f5f5 !important;
  border-radius: 12px !important;
  margin-top: 10px !important;
}
:where(.css-dev-only-do-not-override-p8b6i).ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #00264d;
  border-color: #00264d;
  background: #ffffff;
}
#components-slider-demo-mark h4 {
  margin: 0 0 16px;
}

#components-slider-demo-mark .ant-slider-with-marks {
  margin-bottom: 44px;
}
.ant-slider {
  display: none;
}

.ant-slider .ant-slider-handle::after {
  content: "";
  box-shadow: 0 0 0 2px #00264d !important;
}
.ant-slider .ant-slider-track {
  background-color: #00264d !important;
}
.ant-slider .ant-slider-dot-active {
  border-color: #00264d !important;
}
.ant-slider .ant-slider-dot {
  width: 1px !important;
  border-radius: 0% !important;
}
.ant-btn-default {
  background: #3898f8 !important;
  color: #fff !important;
  font-weight: 800;
  border-radius: 12px;
  border: none;
  height: 40px;
  font-size: 16px;
}
.ant-btn-default:hover {
  background-color: #1a88ff !important;
  transition: 0.3s;
  border: none !important;
}
/* background: rgb(0,38,77);
background: linear-gradient(30deg, rgba(0,38,77,1) 0%, rgba(77,162,255,1) 100%); */
.ant-modal-header {
  margin-bottom: 20px !important;
}
.ant-modal-title {
  color: #000 !important;
}
.ant-form-item {
  color: #000 !important;
}







