@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: helvetica, "Poppins", sans-serif !important;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.fade.authModal.modal .modal-dialog {
  max-width: 700px;
}
.auth-passwords div {
  /* width: 25px !important; */
  /* display: flex; */
  justify-content: space-evenly;
}
.auth-passwords input {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid #a8a8a8;
  border-radius: 5px;
}
.auth-password input {
  width: 30px !important;
}